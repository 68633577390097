import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IMessage } from 'src/app/common/services/interfaces/imessage-model';

/**
 * Represents the ThreadMessageComponent.
 */
@Component({
  selector: 'app-thread-message',
  templateUrl: './thread-message.component.html',
  styleUrl: './thread-message.component.css'
})
export class ThreadMessageComponent {
  @Input() message!: IMessage;
  @Output() imageLoaded = new EventEmitter();
  @ViewChild('messageImage') messageImage!: ElementRef;

  selectedImageSrc: string = "";
  isFullScreenVisible: boolean = false;
  imageRect: DOMRect | null = null;

  constructor() { }

  /**
   * Opens the full screen image.
   * @param imageSrc - The source of the image.
   * @param imageElement - The HTML element of the image.
   */
  openFullscreenImage(imageSrc: string, imageElement: HTMLElement) {
    this.selectedImageSrc = imageSrc;
    this.isFullScreenVisible = true;
    this.imageRect = imageElement.getBoundingClientRect();
  }

  /**
   * Opens the full screen video.
   * @param videoSrc - The source of the video.
   */
  openFullscreenVideo(videoSrc: string) {
    window.open(videoSrc, '_blank');
  }

  /**
   * Closes the full screen image.
   */
  closeFullScreenImage() {
    this.selectedImageSrc = "";
    this.isFullScreenVisible = false;
    this.imageRect = null;
  }

  /**
   * Event handler for when the image is loaded.
   */
  onImageLoaded(): void {
    this.imageLoaded.emit();
  }
}
