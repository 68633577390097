<mat-toolbar #barTop class="bar-top">
	<div #facilityNameDiv class="facility-name">{{ facilityName }}</div>
	<button #helpButton mat-icon-button aria-label="Help" class="help-button" (click)="openHelpPage()">
		<mat-icon fontIcon="help"></mat-icon>
	</button>
</mat-toolbar>
<mat-toolbar class="bar-patient-name">
	<div class="patient-name-wrapper">
		<div class="icon-circle">
			<mat-icon
				id="personal-injury-icon"
				aria-label="personal injury icon"
				fontIcon="personal_injury"></mat-icon>
		</div>
		<span>{{ patientName }}</span>
	</div>
</mat-toolbar>
