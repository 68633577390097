import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { environment } from "../../environments/environment";

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({ declarations: [],
    exports: [TranslateModule], imports: [CommonModule,
        TranslateModule.forRoot({
            defaultLanguage: environment.envVariables.DEFAULT_LOCALE,
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        })], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class NgxTranslateModule {}
