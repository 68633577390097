import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Service for notifying when the reaction initialization is complete.
 */
@Injectable({
  providedIn: 'root'
})
export class ReactionInitNotifierService {
  private reactionInitCompleteSource = new Subject<void>();

  reactionInitComplete$ = this.reactionInitCompleteSource.asObservable();

  emitInitComplete(): void {
    this.reactionInitCompleteSource.next();
  }
}
