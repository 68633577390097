import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IMessage } from 'src/app/common/services/interfaces/imessage-model';

@Component({
  selector: 'app-thread-chat',
  templateUrl: './thread-chat.component.html',
  styleUrl: './thread-chat.component.css'
})
export class ThreadChatComponent {
  @Input() messages: IMessage[] = [];
  @Output() imageLoaded = new EventEmitter();
  @Input() newMessageNotificationVisible: boolean = false;

  /**
   * Event handler for when an image is loaded.
   * Emits the `imageLoaded` event.
   */
  onImageLoaded(): void {
    this.imageLoaded.emit();
  }
}
