<div class="overlay-container" *ngIf="isVisible || isClosing">
	<div class="overlay" *ngIf="isVisible" @fadeBackground (@fadeBackground.done)="onAnimationDone($event)"></div>
	<div class="header" *ngIf="isVisible" @fadeHeader>
		<button class="close-button" (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
	</div>
	<div class="image-container" *ngIf="isVisible" [@zoomImage]="getAnimationParams()">
		<img [src]="imgSrc" alt="Full Screen Image" />
	</div>
</div>
