<div class="thread-container">
	<app-thread-bar
		#appBar
		[facilityName]="facilityName"
		[patientName]="patientName"></app-thread-bar>
	<div #chatThread class="chat-wrapper">
		<app-thread-chat (imageLoaded)="onImageLoaded()" [messages]="messages" [newMessageNotificationVisible]="newMessageNotificationVisible"></app-thread-chat>
	</div>
    <app-footer #footer></app-footer>
</div>
