import { Injectable } from "@angular/core";
import { LogLevel } from "./log-level";
import { environment } from "../../../environments/environment";

/**
	 * Logs a message with the specified log level.
	 * @param msg - The message to be logged. (optional)
	 * @param logLevel - The log level. Defaults to LogLevel.DEBUG if not specified.
	 */
@Injectable({
	providedIn: "root",
})
export class EaseLoggerService {
	//constructor() {}

	log(msg?: any, logLevel: LogLevel = LogLevel.DEBUG) {
		if (
			environment.envVariables.IS_DEBUG &&
			environment.envVariables.APP_ENV !== "prod"
		) {
			console.log(
				`${LogLevel[logLevel]} => ${new Date().toISOString()}: ${JSON.stringify(
					msg
				)}`
			);
		}
	}
}
