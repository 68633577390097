import { Component } from "@angular/core";

@Component({
	selector: "app-footer",
	template: `
		<footer class="full-center">
			<div class="row full-center">
					<img
						class="centered-logo"
						src="assets/images/ease-logo.png"
						alt="Ease Logo" />
			</div>
		</footer>
	`,
	styles: [
		`
			footer {
				display: flex;
				flex-direction: row;
				width: 100%;
				background-color: white;
				margin-top: 2.5rem;
				position: relative;
			}

			.centered-logo {
				height: 2.5rem;
				width: auto;
				margin-bottom: 2.5rem;
			}
		`,
	],
})
export class FooterComponent { }
