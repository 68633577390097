<div class="column">
	<div class="row">
		<div
			class="message-content"
			[ngClass]="{ 'no-background': message.mediaType !== 'text' }"
			*ngIf="message.mediaType !== 'text'">
			<ng-container *ngIf="message?.mediaType === 'video'">
				<video
					#messageVideo
					id="message-video"
					class="media-asset"
					controls
					(click)="openFullscreenVideo(message.mediaURL)">
					<source src="{{ message.mediaURL }}" type="video/mp4" />
				</video>
			</ng-container>
			<ng-container *ngIf="message?.mediaType === 'photo'">
				<img
					#messageImage
					id="message-image"
					class="media-asset"
					src="{{ message.mediaURL }}"
					alt="{{ message.text }}"
					(load)="onImageLoaded()"
					(click)="openFullscreenImage(message.mediaURL, messageImage)" />
			</ng-container>
		</div>
	</div>
	<div class="row">
		<div class="message-content">
			<ng-container
				*ngIf="message?.text?.length !== 0">
				<div class="message-text">{{ message.text }}</div>
			</ng-container>
		</div>
	</div>
	<div class="row">
		<div class="box">
			<app-emoji-btn [messageId]="+message.id"></app-emoji-btn>
		</div>
	</div>
	<div class="row">
		<div class="text-muted message-date">
			{{ message.createTime | timeAgo }}
		</div>
	</div>
</div>

<app-thread-full-screen-media
	[imgSrc]="selectedImageSrc"
	[isVisible]="isFullScreenVisible"
	[initialRect]="imageRect"
	(fullScreenClosed)="closeFullScreenImage()"></app-thread-full-screen-media>
