import { Component, ElementRef, Input, QueryList, ViewChildren } from "@angular/core";
import { EwrService } from "../../services/ewr.service";
import { IMessageReaction } from "../../services/interfaces/ireaction-response";
import { EaseLoggerService } from "../../services/logger/ease-logger.service";
import { ParametersService } from "../../services/parameters.service";
import { ReactionInitNotifierService } from "../../services/reaction-init-notifier.service";
import { IButton } from "./ibutton-model";

@Component({
	selector: "app-emoji-btn",
	template: `
		<div class="emoji-wrapper">
			<div class="emoji-button" *ngFor="let emoji of emojiReactions" [ngClass]="{ 'selected': emoji.selected }" (click)="onEmojiSelected(emoji)">
				<ng-container *ngIf="emoji.visible">
					<img #emojiImg
						src="{{ emoji.imgSrc }}"
						[alt]="emoji.btnName"
						[class]="emoji.className"
						[name]="emoji.btnName" />
				</ng-container>
			</div>
		</div>
	`,
	styles: [
		`	
			.emoji-wrapper {
				display: flex;
				flex-direction: row;
			}

			.emoji-button {
				height: 2rem;
				width: 3.25rem;
				margin-right: 0.5rem;
				border-radius: 1rem;
				border: 1px solid var(--reation-button-border-color);
				cursor: pointer;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.btn-heart,
			.btn-thumb {
				width: auto;
				height: 1.25rem;
			}

			.selected {
				background-color: var(--reaction-button-selected-color);
				border: 1px solid var(--reaction-button-selected-color);
				cursor: pointer;
			}
		`,
	],
})
/**
 * Represents a component for displaying and selecting emoji reactions.
 */
export class EmojiBtnComponent {
	@Input() messageId!: number;
	@Input() selected!: boolean;
	@ViewChildren('emojiImg') emojiImgs!: QueryList<ElementRef>;

	protected emojiReactions: IButton[] = [
		{
			btnName: "thumb",
			className: "btn-thumb",
			imgSrc: "assets/images/reaction-thumb-ios.png",
			visible: true,
			selected: false,
		},
		{
			btnName: "heart",
			className: "btn-heart",
			imgSrc: "assets/images/reaction-heart-ios.png",
			visible: true,
			selected: false,
		},
	];

	constructor(
		private readonly logger: EaseLoggerService,
		private readonly ewr: EwrService,
		private readonly parameters: ParametersService,
		private reactionInitComplete: ReactionInitNotifierService,
	) { }

	/**
	 * Updates the state of the emojis based on the message reaction state.
	 */
	updateEmojiState(): void {
		this.emojiImgs.forEach((emoji, index) => {
			this.emojiReactions[index].selected = this.parameters.getMessageReactionState(this.messageId, emoji.nativeElement.name);
		});
	}

	ngAfterViewInit(): void {
		this.reactionInitComplete.reactionInitComplete$.subscribe(() => {
			this.updateEmojiState();
		});
	}

	/**
	 * Handles the selection of an emoji button.
	 * @param emoji - The selected emoji button.
	 */
	onEmojiSelected(emoji: IButton): void {
		emoji.selected = !emoji.selected;

		this.logger.log(`${this.messageId} -> ${emoji.btnName} : ${emoji.selected}`);

		// Change tracked reaction state
		this.parameters.setMessageReactionState(this.messageId, emoji.btnName, emoji.selected);

		const reaction: IMessageReaction = {
			messageId: this.messageId,
			heart: this.parameters.getMessageReactionState(this.messageId, "heart"),
			thumb: this.parameters.getMessageReactionState(this.messageId, "thumb"),
			prayer: false,
			receivingIdentityId: (this.parameters.getReceiverId() !== null ? this.parameters.getReceiverId()!.toString() : ""),
		};

		this.ewr.messageReaction(reaction).subscribe(() => {
			this.logger.log(`Reaction Sent`);
		});
	}
}
