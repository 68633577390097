<form [formGroup]="verificationForm" (ngSubmit)="onSubmit()">
	<div class="verification-form-container row full-center">
		<div class="row verification-form-row full-center">
			<div
				class="form-field last-name-field"
				[ngClass]="{
					'is-invalid': (submitted && phoneNumber.errors) || verificationFailed
				}">
				<label for="lastName" class="form-label">Patient Last Name</label>
				<input
					type="text"
					id="lastName"
					formControlName="lastName"
					class="form-control"
					placeholder="Patient Last Name"
					autocomplete="off" />
			</div>
		</div>
		<div class="row verification-form-row full-center">
			<div
				class="form-field phone-number-field"
				[ngClass]="{
					'is-invalid': (submitted && phoneNumber.errors) || verificationFailed
				}">
				<label for="phoneNumber" class="form-label">Your Phone Number</label>
				<input
					id="phoneNumber"
					formControlName="phoneNumber"
					class="form-control"
					placeholder="(XXX) XXX-XXXX"
					mask="(000) 000-0000"
					autocomplete="off" />
			</div>
		</div>
		<div *ngIf="verificationFailed" class="invalid-feedback">
			<div class="form-error-text">Last name or phone number is invalid</div>
		</div>
		<button
			class="btn-continue"
			type="submit"
			[ngClass]="{ disabled: verificationForm.invalid }">
			{{ "common.next-button" | translate | uppercase }}
		</button>
	</div>
</form>
