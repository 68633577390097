import { Injectable } from "@angular/core";
import {
	MatSnackBar,
	MatSnackBarHorizontalPosition,
	MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { StorageService } from "./storage.service";
import { EaseLoggerService } from "./logger/ease-logger.service";
import { SnackbarCookiesComponent } from "../components/snackbar-cookies/snackbar-cookies.component";
import { ComponentType } from "@angular/cdk/overlay";

/**
 * Service for displaying snackbars.
 */
@Injectable({
	providedIn: "root",
})
export class SnackbarService {
	constructor(
		private readonly snackbar: MatSnackBar,
		private readonly storgeService: StorageService,
		private readonly logger: EaseLoggerService
	) {}

	/**
	 * Opens a snackbar that automatically dismisses after a specified duration.
	 * @param message - The message to display in the snackbar.
	 * @param duration - The duration in milliseconds for which the snackbar should be displayed.
	 * @param action - The label for the action button in the snackbar.
	 * @param verticalPosition - The vertical position of the snackbar. Allowed values are 'top' or 'bottom'.
	 * @param horizontalPosition - The horizontal position of the snackbar. Allowed values are 'start', 'center', 'end', 'left', or 'right'.
	 * @param panelClass - The CSS class(es) to apply to the snackbar panel.
	 */
	openAutoDismiss(
		message: string,
		duration: number,
		action: string,
		verticalPosition: MatSnackBarVerticalPosition,
		horizontalPosition: MatSnackBarHorizontalPosition,
		panelClass: string
	) {
		const snack = this.snackbar.open(message, action, {
			duration: duration,
			verticalPosition: verticalPosition,
			horizontalPosition: horizontalPosition,
			panelClass: [panelClass],
		});
		snack.onAction().subscribe(() => {
			console.log("This will be called when snackbar button clicked");
		});
	}

	/**
	 * Opens a snackbar with a close button.
	 * @param id - The ID of the snackbar.
	 * @param message - The message to display in the snackbar.
	 * @param verticalPosition - The vertical position of the snackbar. Allowed values are 'top' or 'bottom'.
	 * @param horizontalPosition - The horizontal position of the snackbar. Allowed values are 'start', 'center', 'end', 'left', or 'right'.
	 * @param panelClass - The CSS class(es) to apply to the snackbar panel.
	 */
	open(
		id: string,
		message: string,
		verticalPosition: MatSnackBarVerticalPosition,
		horizontalPosition: MatSnackBarHorizontalPosition,
		panelClass: string
	) {
		const snackBar = this.storgeService.getSnackBarId();
		if (snackBar === null) {
			const snack = this.snackbar.open(message, "Close", {
				verticalPosition: verticalPosition,
				horizontalPosition: horizontalPosition,
				panelClass: [panelClass],
			});

			snack.afterDismissed().subscribe(() => {
				console.log("This will be shown after snackbar disappeared");
			});
			snack.onAction().subscribe(() => {
				console.log("This will be called when snackbar button clicked");
			});

			this.storgeService.storeSnackBarId(id);
		} else {
			this.logger.log(`SnackBar ${id} has already been displayed`);
		}
	}

	/**
	 * Opens a snackbar with a custom component for displaying cookies.
	 * @param snackbarId - The ID of the snackbar.
	 */
	openCookies(snackbarId: string) {
		const snackBar = this.storgeService.getSnackBarId();
		if (snackBar === null) {
			this.snackbar.openFromComponent(SnackbarCookiesComponent, {
				data: {
					preClose: () => {
						this.snackbar.dismiss();
					},
				},
				panelClass: ["cookies-snackbar"],
				verticalPosition: "bottom",
				horizontalPosition: "center",
			});
			this.storgeService.storeSnackBarId(snackbarId);
		}
	}

	/**
	 * Opens a snackbar with a custom component for user notification.
	 * @param component - The component to display in the snackbar.
	 * @param cssClass - The CSS class(es) to apply to the snackbar panel.
	 * @param duration - The duration in milliseconds for which the snackbar should be displayed.
	 */
	userNotification(
		component: ComponentType<any>,
		cssClass: string,
		duration: number
	) {
		this.snackbar.openFromComponent(component, {
			duration: duration,
			data: {
				preClose: () => {
					this.snackbar.dismiss();
				},
			},
			panelClass: [cssClass],
			verticalPosition: "top",
			horizontalPosition: "center",
		});
	}
}
