import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/app/environments/environment';

/**
 * Intercepts HTTP requests to prepend the API base URL.
 *
 * This interceptor modifies outgoing HTTP requests to include the base API URL
 * defined in the environment configuration. It ensures all HTTP requests are directed
 * to the correct API endpoint.
 *
 * @param req The outgoing HTTP request.
 * @param next The next handler in the interceptor chain.
 * @returns An observable of the HTTP event.
 */
@Injectable({
  providedIn: 'root'
})
export class ApiUrlInterceptorService implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    // Check if the request URL an api request
    if (req.url.startsWith('/api/')) {
      const apiReq = req.clone({ url: `${environment.envVariables.API_URL}${req.url}` });
      return next.handle(apiReq);
    }

    return next.handle(req);
  }
}
