import { Injectable } from "@angular/core";
import { EaseLoggerService } from "./logger/ease-logger.service";

/**
 * Service for storing and retrieving data from local storage and session storage.
 */
@Injectable({
	providedIn: "root",
})
export class StorageService {
	SNACKBARS_IDS = "snackbarIds";

	constructor(private readonly logger: EaseLoggerService) { }

	/**
	 * Stores the snackbar ID in local storage.
	 * @param snackBarId The snackbar ID to be stored.
	 */
	storeSnackBarId(snackBarId: string) {
		const snackBar = JSON.stringify({
			snackbarId: snackBarId,
		});
		this.logger.log(`StorageService.snackBar: ${snackBarId}`);
		localStorage.setItem(this.SNACKBARS_IDS, snackBar);
	}

	/**
	 * Retrieves the snackbar ID from local storage.
	 * @returns The snackbar ID, or null if it doesn't exist.
	 */
	getSnackBarId() {
		const snackBar = localStorage.getItem(this.SNACKBARS_IDS);

		if (snackBar != null) {
			return JSON.parse(snackBar);
		}
		return null;
	}
	/**
	 * Clears the local storage and session storage.
	 */
	logout() {
		localStorage.clear();
		sessionStorage.clear();
	}
}
