import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-thread-new-message-notification',
  templateUrl: './thread-new-message-notification.component.html',
  styleUrl: './thread-new-message-notification.component.css'
})
export class ThreadNewMessageNotificationComponent {
  @Input() visible: boolean = false;
}
