import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

/**
 * Represents a component for the verification form.
 */
@Component({
  selector: 'app-verification-form',
  templateUrl: './verification-form.component.html',
  styleUrl: './verification-form.component.css'
})
export class VerificationFormComponent implements OnChanges {
  @Input() verificationFailed: boolean = false;
  @Output() formSubmitted = new EventEmitter();

  submitted = false;
  phoneNumberPattern = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

  verificationForm: FormGroup = new FormGroup({
    lastName: new FormControl(''),
    phoneNumber: new FormControl(''),
  });

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.verificationForm = this.fb.group({
      lastName: ['', Validators.required],
      phoneNumber: [
        '',
        [
          Validators.required,
          Validators.pattern(this.phoneNumberPattern)
        ]
      ]
    });
  }

  ngOnInit() { };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['verificationFailed']) {
      this.cdr.detectChanges();
    }
  }

  get lastName(): AbstractControl {
    return this.verificationForm.controls['lastName'];
  }

  get phoneNumber(): AbstractControl {
    return this.verificationForm.controls['phoneNumber'];
  }

  /**
   * Handles the form submission event.
   * Emits the serialized form value if the form is valid.
   */
  onSubmit(): void {
    this.submitted = true;

    if (this.verificationForm.invalid) {
      return;
    }

    this.formSubmitted.emit(JSON.stringify(this.verificationForm.value));
  }

  /**
   * Resets the verification form and sets the submitted flag to false.
   */
  onReset(): void {
    this.submitted = false;
    this.verificationForm.reset();
  }
}
