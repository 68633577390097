import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { catchError, interval, of, Subscription, switchMap } from 'rxjs';
import { EwrService } from 'src/app/common/services/ewr.service';
import { IBrandingModel } from 'src/app/common/services/interfaces/ibranding-model';
import { IMessage } from 'src/app/common/services/interfaces/imessage-model';
import { IMessageReaction } from 'src/app/common/services/interfaces/ireaction-response';
import { EaseLoggerService } from 'src/app/common/services/logger/ease-logger.service';
import { ParametersService } from 'src/app/common/services/parameters.service';
import { ReactionInitNotifierService } from "src/app/common/services/reaction-init-notifier.service";
import { environment } from 'src/app/environments/environment';

/**
 * Represents the ThreadComponent class.
 * This component is responsible for displaying a chat thread.
 */
@Component({
  selector: 'app-thread',
  templateUrl: './thread.component.html',
  styleUrl: './thread.component.css'
})
export class ThreadComponent implements OnInit, OnDestroy {
  @ViewChild('appBar', { read: ElementRef, static: false }) appBar!: ElementRef;
  @ViewChild('chatThread', { read: ElementRef, static: false }) chatThread!: ElementRef;
  @ViewChild('footer', { read: ElementRef, static: false }) footer!: ElementRef;
  patientName: string = "Doe, John";
  facilityName: string = "Default Facility Name";
  messages: IMessage[] = [];
  newMessageNotificationVisible: boolean = false;
  newMessageNotificationTimeout: NodeJS.Timeout | null = null;
  private messageSubscription!: Subscription;

  constructor(
    private readonly logger: EaseLoggerService,
    private readonly parameters: ParametersService,
    private readonly ewr: EwrService,
    private reactionInitComplete: ReactionInitNotifierService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    const facilityName: string | null = this.parameters.getFacilityName();
    const patientFamilyName: string | null = this.parameters.getPatientFamilyName();
    const patientGivenName: string | null = this.parameters.getPatientGivenName();

    if (patientFamilyName !== null && patientGivenName !== null) {
      this.patientName = this.parameters.getPatientFamilyName() + ', ' + this.parameters.getPatientGivenName();
    }

    if (facilityName !== null) {
      this.facilityName = facilityName;
    }
  };

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.ewr.getHospitalBranding()
      .subscribe((data: IBrandingModel) => {
        this.parameters.setFacilityName(data.facilityName);
        this.parameters.setFacilityId(data.facilityId);
        this.facilityName = data.facilityName;
      });

    this.fetchMessages();

    // Check if there is a new message
    if (this.parameters.getNewMessageId() !== null) {
      this.showNewMessageNotification();
    }
  }

  /**
   * Cleans up resources when the component is destroyed.
   */
  ngOnDestroy(): void {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
  }

  /**
   * Performs additional initialization after the view is initialized.
   */
  ngAfterViewInit(): void {
    this.adjustChatHeight();
    window.addEventListener('resize', this.adjustChatHeight.bind(this));

    // setTimeout(() => {
    //   this.scrollToBottom();
    //   this.logger.log('Scrolled to bottom');
    // }, 1000);

    // Set up polling for new message
    this.messageSubscription = interval(environment.envVariables.MESSAGES_PULL_INTERVAL).pipe(
      switchMap(() => this.ewr.getLatestMessage()),
      catchError(error => {
        this.logger.log('Error fetching data:', error);
        return of(null);
      })
    ).subscribe({
      next: data => {
        if (data !== null && data.id !== this.messages[this.messages.length - 1]?.id) {
          this.messages.push(data);
          this.parameters.setMessageReactionState(this.messages[this.messages.length - 1].id)
          this.showNewMessageNotification();

          setTimeout(() => {
            this.scrollToBottom();
          })
        }
      }
    });
  }

  /**
   * Scrolls the chat thread to the bottom.
   */
  scrollToBottom(): void {
    const chatWrapperElement = this.chatThread.nativeElement;
    if (chatWrapperElement) {
      chatWrapperElement.scrollTop = chatWrapperElement.scrollHeight;

      this.changeDetectorRef.detectChanges();
    } else {
      console.error('chatThread native element is not defined');
    }
  }

  /**
   * Adjusts the height of the chat thread based on the window size.
   */
  adjustChatHeight(): void {
    const appBarHeight = this.appBar.nativeElement.offsetHeight;
    const totalHeight = window.innerHeight;

    const chatHeight = totalHeight - appBarHeight;
    this.chatThread.nativeElement.style.height = `${chatHeight}px`;
  }

  /**
   * Callback function when an image is loaded.
   * Scrolls the chat thread to the bottom.
   */
  onImageLoaded(): void {
    this.scrollToBottom();
  }

  private showNewMessageNotification(): void {
    if (this.newMessageNotificationTimeout !== null) {
      clearTimeout(this.newMessageNotificationTimeout);
    }

    this.newMessageNotificationVisible = true;

    this.newMessageNotificationTimeout = setTimeout(() => {
      this.newMessageNotificationVisible = false;
      this.newMessageNotificationTimeout = null;
    }, 10000); // Hide after 10 seconds
  }

  private fetchMessages(): void {
    this.ewr.getMessages().subscribe({
      next: (messages: IMessage[]) => {
        this.messages = messages;

        this.messages.sort((a: IMessage, b: IMessage) => (new Date(a.createTime).getTime() - new Date(b.createTime).getTime()));

        // Initialize the message reactions
        this.messages.forEach((message) => {
          this.parameters.setMessageReactionState(message.id);
        });
        this.logger.log(`Messages: ${this.messages.length}`);

        this.fetchReactions();

        setTimeout(() => {
          this.scrollToBottom();
        });
      },
      error: (error: any) => {
        this.logger.log(`Error fetching messages: ${error}`);
      }
    });
  }

  private fetchReactions(): void {
    this.ewr.getReactions().subscribe({
      next: (reactions: IMessageReaction[]) => {
        reactions.forEach((reaction: IMessageReaction) => {
          this.parameters.setMessageReactionState(reaction.messageId, "heart", reaction.heart);
          this.parameters.setMessageReactionState(reaction.messageId, "thumb", reaction.thumb);
        });

        this.logger.log(`Reactions: ${reactions.length}`);
        this.reactionInitComplete.emitInitComplete();
      },
      error: (error: any) => {
        this.logger.log(`Error fetching reactions: ${error}`);
      }
    });
  }
}
