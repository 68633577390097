import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, throwError } from "rxjs";
import { EventData } from "../classes/event-data";
import { SnackbarErrorComponent } from "../components/snackbar-notification/snackbar-error.component";
import { SnackbarTypes } from "../components/snackbar-notification/snackbar-types";
import { EventBusService } from "./event-bus.service";
import { EaseLoggerService } from "./logger/ease-logger.service";
import { LogLevel } from "./logger/log-level";
import { SnackbarService } from "./snackbar.service";

/**
 * Intercepts HTTP responses to handle errors globally.
 *
 * This interceptor processes HTTP errors and provides centralized error handling.
 * It handles specific error statuses, such as 401 (unauthorized) to refresh tokens,
 * and 404 (not found) to show user notifications. It logs errors and emits events
 * for specific actions like logging out.
 *
 * @param request The outgoing HTTP request.
 * @param next The next handler in the interceptor chain.
 * @returns An observable of the HTTP event.
 */
@Injectable({
	providedIn: "root",
})
export class ErrorInterceptorService implements HttpInterceptor {
	constructor(
		private readonly logger: EaseLoggerService,
		private readonly eventBusService: EventBusService,
		private readonly snackBar: SnackbarService
	) { }

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((err) => {
				// if ([404].includes(err.status)) {
				// 	if (request.url.includes("patient-verification")) {
				// 		this.snackBar.userNotification(
				// 			SnackbarPatientNotFoundComponent,
				// 			SnackbarTypes.WARNING,
				// 			6000
				// 		);
				// 	} else if (request.url.includes("refresh-token")) {
				// 		this.snackBar.userNotification(
				// 			SnackbarPatientDischargedComponent,
				// 			SnackbarTypes.ERROR,
				// 			6000
				// 		);
				// 		this.eventBusService.emit(new EventData("logout", null));
				// 	} else if (request.url.includes("hospital-branding")) {
				// 		this.snackBar.userNotification(
				// 			SnackbarPatientNotRegisteredComponent,
				// 			SnackbarTypes.ERROR,
				// 			6000
				// 		);
				// 	}
				// } else 
				if (err.status === 0 || err.status === 500) {
					this.snackBar.userNotification(
						SnackbarErrorComponent,
						SnackbarTypes.ERROR,
						6000);

					if (request.url.includes("thread")) {
						this.eventBusService.emit(new EventData("logout", null));
					}
				}
				
				const error = (err && err.error && err.error.message) || err.statusText;

				this.logger.log(`============ >>>> ${err.status}`, LogLevel.ERROR);
				return throwError(() => error);
			})
		);
	}
}
