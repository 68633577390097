<div class="container column full-center">
	<!-- Branding -->
	<div class="row" id="app-branding-row" *ngIf="showLogo">
		<app-branding
			[logoHeight]="'auto'"
			[logoWidth]="'auto'"
			(logoStatus)="handleLogoStatus($event)" />
	</div>

	<!-- Welcome Text -->
	<div class="row" id="text-section" [ngClass]="{ 'image-loaded': showLogo }">
		<h5 id="welcome-text">
			{{ "verification.title" | translate | titlecase }}
		</h5>
		<p id="get-started-text">
			{{ "verification.subtitle" | translate }}
		</p>
	</div>

	<!-- Form Section -->
	<div class="row full-center verification-form">
		<app-verification-form
			(formSubmitted)="handleFormSubmission($event)"
			[verificationFailed]="verificationFailed"></app-verification-form>
	</div>

	<app-footer></app-footer>
</div>
