import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ParametersService } from 'src/app/common/services/parameters.service';
import { AuthService } from 'src/app/common/services/auth.service';
import { EwrService } from 'src/app/common/services/ewr.service';
import { catchError, of } from 'rxjs';

/**
 * Represents the verification component.
 */
@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrl: './verification.component.css'
})
export class VerificationComponent {
  showLogo: boolean = true;
  verificationFailed: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly parameters: ParametersService,
    private readonly authService: AuthService,
    private readonly ewr: EwrService
  ) {
  }

  /**
   * Handles the logo status.
   * @param status - The status of the logo.
   */
  handleLogoStatus(status: boolean): void {
    this.showLogo = status;
  }

  /**
   * Handles the form submission.
   * @param formData - The form data to be submitted.
   */
  handleFormSubmission(formData: string): void {
    const parsedData = JSON.parse(formData);
    this.ewr.joinWebReceiver(parsedData.lastName, parsedData.phoneNumber).pipe(
      catchError(error => {
        this.verificationFailed = true;
        return of(null);
      })
    ).subscribe(response => {
      if (response) {
        let receiverID: number | null = this.parameters.getReceiverId();
        if (receiverID === null) {
          return;
        }

        this.authService.storeCredentials(receiverID, response.id, response.secretKey);
        this.parameters.setPatientFamilyName(response.familyName);
        this.parameters.setPatientGivenName(response.givenName);
        this.parameters.setProcedureId(parseInt(response.procedureId));

        let redirectUrl: string = `/messages/${this.parameters.getFacilityName()}/${this.parameters.getFacilityId()}/${this.parameters.getReceiverId()}`;
        this.router.navigate([redirectUrl]);
      }
    });
  }
}
