import { Component, Inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";

@Component({
	selector: "app-snackbar-cookies",
	template: `
		<div class="snackbar-wrapper">
			<div class="snackbar-title">
				{{ "toast-messages.cookies-notice.snackbar-title" | translate }}
			</div>
			<a>
				{{ "toast-messages.cookies-notice.snackbar-notice" | translate }}
			</a>
			<a
			class="policy-link"
			href="https://www.stryker.com/gb/en/legal/global-policy-statement.html/">
			{{ "toast-messages.cookies-notice.snackbar-link" | translate }}
		</a>
		<div class="close-btn-container">
			<button class="btn close-btn" type="button" (click)="dismiss()">
				{{ "common.close-button" | translate | uppercase }}
			</button>
		</div>
		</div>
	`,
	styles: [
		`
			.cookies-snackbar {
				background: var(--color-primary);
				color: var(--color-text);
			}
			
			.snackbar-wrapper {
				background: var(--color-primary);
				color: var(--color-text);
				line-height: 1rem;
			}
			
			.snackbar-title {
				text-align: center;
				margin-bottom: 0.5rem;
				font-size: 1rem;
				font-weight: 500;
			}
				
			.close-btn-container {
				display: flex;
				justify-content: center;
			}

			.close-btn {
					color: var(--color-white);
					background: var(--color-mid-gray);
					font-size: 0.875rem;
					padding-block: 0.625rem;
					padding-inline: 1.8rem;
					border-radius: 1.4rem;
					margin-top: 1rem;
			}
			
			a {
				color: var(--color-text);
				font-size: 0.75rem;
				margin: 0;
				text-decoration: underline;
				padding: 0rem;
			}
		`,
	],
})
export class SnackbarCookiesComponent {
	constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }
	dismiss() {
		this.data.preClose();
	}
}
