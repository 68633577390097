import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";

/**
 * Intercepts HTTP requests to add common headers.
 *
 * This interceptor adds headers to disable caching for all outgoing HTTP requests.
 * It ensures that requests are not cached by the browser, which is useful for fetching
 * the latest data.
 *
 * @param request The outgoing HTTP request.
 * @param next The next handler in the interceptor chain.
 * @returns An observable of the HTTP event.
 */
@Injectable({
	providedIn: "root",
})
export class HeadersInterceptorService implements HttpInterceptor {
	constructor() { }

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		const nextReq = request.clone({
			headers: request.headers
				.set("Cache-Control", "no-cache, no-store, must-revalidate")
				.set("Pragma", "no-cache"),
		});

		return next.handle(nextReq);
	}
}
