import { Component, Inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";

@Component({
	selector: "app-snackbar-notification",
	template: `
		<div class="container-fluid snack-wrapper">
			<div class="row">
				<div class="col-9">
					<p class="notification-text">
						{{ "toast-messages.patient-discharged" | translate }}
					</p>
				</div>
				<div class="col-3">
					<button class="btn ok-btn" type="button" (click)="dismiss()">
						{{ "common.ok-button" | translate | uppercase }}
					</button>
				</div>
			</div>
		</div>
	`,
	styles: [
		`
			.ok-btn {
				text-align: center;
			}
		`,
	],
})
export class SnackbarPatientDischargedComponent {
	constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}
	dismiss() {
		this.data.preClose();
	}
}
