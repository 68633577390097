import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';
import { ParametersService } from '../services/parameters.service';

/**
 * A guard that redirects unauthenticated users to the verification page.
 * @param route - The activated route snapshot.
 * @param state - The router state snapshot.
 * @returns A boolean indicating whether the user is authenticated.
 */
export const authRedirectGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const parameters = inject(ParametersService);

  const receiverId: string | null = route.paramMap.get('receiverId');
  const facilityName: string | null = route.paramMap.get('facilityName');
  const facilityId: string | null = route.paramMap.get('facilityId');
  
  if (receiverId) {
    parameters.setReceiverId(parseInt(receiverId));
  } else {
    return false;
  }

  if (!authService.isAuthenticated(parseInt(receiverId))) {
    let redirectUrl: string = `/invite/${facilityName}/${facilityId}/${receiverId}`;
    router.navigate([redirectUrl]);
    return false;
  }

  return true;
};
