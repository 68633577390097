import { animate, AnimationEvent, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Component for displaying full-screen media in a thread.
 */
@Component({
  selector: 'app-thread-full-screen-media',
  templateUrl: './thread-full-screen-media.component.html',
  styleUrl: './thread-full-screen-media.component.css',
  animations: [
    // Animation triggers for zooming the image, fading the background, and fading the header
    trigger('zoomImage', [
      transition(':enter', [
        style({
          opacity: 0,
          top: '{{startTop}}px',
          left: '{{startLeft}}px',
          width: '{{startWidth}}px',
          height: '{{startHeight}}px',
        }),
        animate('100ms ease-in', style({
          opacity: 1,
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
        }))
      ], { params: { startTop: 0, startLeft: 0, startWidth: 0, startHeight: 0 } }),
      transition(':leave', [
        animate('100ms ease-out', style({
          opacity: 0,
          top: '{{startTop}}px',
          left: '{{startLeft}}px',
          width: '{{startWidth}}px',
          height: '{{startHeight}}px',
        }))
      ], { params: { startTop: 0, startLeft: 0, startWidth: 0, startHeight: 0 } })
    ]),
    trigger('fadeBackground', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms 100ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('100ms ease-out', style({ opacity: 0 }))
      ])
    ]),
    trigger('fadeHeader', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms 150ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('100ms ease-out', style({ opacity: 0 }))
      ])
    ])
  ],
})
export class ThreadFullScreenMediaComponent {
  @Input() imgSrc !: string; // The source URL of the image to display
  @Input() isVisible = false; // Whether the component is visible or not
  @Input() initialRect: DOMRect | null = null; // The initial position and size of the image
  @Output() fullScreenClosed = new EventEmitter(); // Event emitted when the full-screen mode is closed

  public isClosing: boolean = false; // Whether the component is currently closing

  /**
   * Closes the full-screen mode.
   */
  close() {
    this.isClosing = true;
    this.isVisible = false;
  }

  /**
   * Event handler for the animation done event.
   * @param event The animation event
   */
  onAnimationDone(event: AnimationEvent) {
    if (event.toState === 'void') {
      this.isClosing = false;
      this.fullScreenClosed.emit();
    }
  }

  /**
   * Gets the animation parameters based on the initial rectangle.
   * @returns The animation parameters
   */
  getAnimationParams() {
    if (this.initialRect) {
      return {
        value: '',
        params: {
          startTop: this.initialRect.top,
          startLeft: this.initialRect.left,
          startWidth: this.initialRect.width,
          startHeight: this.initialRect.height,
        }
      };
    }
    return {
      value: '',
      params: {
        startTop: 0,
        startLeft: 0,
        startWidth: 0,
        startHeight: 0,
      }
    };
  }
}
