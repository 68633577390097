import { Injectable } from "@angular/core";
import { filter, map, Subject, Subscription } from "rxjs";
import { EventData } from "../classes/event-data";

@Injectable({
	providedIn: "root",
})
export class EventBusService {
	private subject$ = new Subject<EventData>();

	constructor() {
		//NoOp
	}

	/**
	 * Emits an event to the event bus.
	 * @param event The event to emit.
	 */
	emit(event: EventData) {
		this.subject$.next(event);
	}

	/**
	 * Subscribes to events with a specific event name and performs an action.
	 * @param eventName The name of the event to subscribe to.
	 * @param action The action to perform when the event is emitted.
	 * @returns A subscription object that can be used to unsubscribe from the event.
	 */
	on(eventName: string, action: any): Subscription {
		return this.subject$
			.pipe(
				filter((e: EventData) => e.name === eventName),
				map((e: EventData) => {
					return e["value"];
				})
			)
			.subscribe(action);
	}
}
