import { Component, ElementRef, Input, ViewChild } from '@angular/core';

/**
 * Component for the thread bar.
 */
@Component({
  selector: 'app-thread-bar',
  templateUrl: './thread-bar.component.html',
  styleUrl: './thread-bar.component.css'
})
export class ThreadBarComponent {
  @Input() facilityName: string = "Facility Name";
  @Input() patientName: string = "Lastname, Firstname";
  @ViewChild('facilityNameDiv', { read: ElementRef, static: false }) facilityNameDiv!: ElementRef;
  @ViewChild('helpButton', { read: ElementRef, static: false }) helpButton!: ElementRef;
  @ViewChild('barTop', { read: ElementRef, static: false }) barTop!: ElementRef;

  private facilityNameMaxFontSize: number = 0;

  /**
   * Lifecycle hook that is called after the view has been initialized.
   */
  ngAfterViewInit(): void {
    this.facilityNameMaxFontSize = parseInt(window.getComputedStyle(this.facilityNameDiv.nativeElement).fontSize);
    // this.adjustFacilityNameSize();
    // window.addEventListener('resize', this.adjustFacilityNameSize.bind(this));
  }

  /**
   * Adjusts the size of the facility name based on the available space.
   */
  adjustFacilityNameSize(): void {
    const facilityNameElement = this.facilityNameDiv.nativeElement;
    const barTopElement = this.barTop.nativeElement;
    const helpButtonWidth = this.helpButton.nativeElement.offsetWidth;
    const barTopWidth = barTopElement.offsetWidth;
    const paddingTop = parseFloat(window.getComputedStyle(barTopElement).paddingTop);
    const paddingBottom = parseFloat(window.getComputedStyle(barTopElement).paddingBottom);
    const maxHeight = barTopElement.offsetHeight - paddingTop - paddingBottom;
    const maxWidth = barTopWidth - (2 * helpButtonWidth);

    // Calculate new width
    const newWidth = barTopWidth - (2 * helpButtonWidth);
    facilityNameElement.style.width = `${newWidth}px`;

    /**
     * Adjusts the font size of the facility name by the given increment.
     * @param increment - The increment to adjust the font size by.
     */
    const adjustFontSize = (increment: number) => {
      const currentFontSize: number = parseInt(window.getComputedStyle(facilityNameElement).fontSize);
      facilityNameElement.style.fontSize = (currentFontSize + increment).toString() + 'px';
    }

    /**
     * Shrinks the font size of the facility name until it fits within the available space.
     */
    const shrinkFontSize = () => {
      adjustFontSize(-1);
      if (facilityNameElement.scrollWidth > maxWidth || facilityNameElement.scrollHeight > maxHeight) {
        shrinkFontSize();
      }
    }

    /**
     * Grows the font size of the facility name until it no longer fits within the available space.
     */
    const growFontSize = () => {
      adjustFontSize(1);
      if (facilityNameElement.scrollWidth <= maxWidth && facilityNameElement.scrollHeight <= maxHeight) {
        if (parseInt(window.getComputedStyle(facilityNameElement).fontSize) < this.facilityNameMaxFontSize) {
          growFontSize();
        } else {
          adjustFontSize(-1); // step back to fit the previous size
        }
      } else {
        adjustFontSize(-1); // step back to fit the previous size
      }
    }

    // Adjust Font Size
    if (facilityNameElement.scrollWidth > maxWidth || facilityNameElement.scrollHeight > maxHeight) {
      shrinkFontSize();
    } else {
      growFontSize();
    }
  }

  /**
   * Opens the help page.
   */
  openHelpPage(): void {
    // Open help dialog
    window.open('https://vocera.stryker.com/s/vocera-ease-faqs', '_blank');
  }
}
