import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DeviceDetectorService, DeviceInfo } from "ngx-device-detector";
import { EwrService } from "src/app/common/services/ewr.service";
import { IPatientDetails } from "src/app/common/services/interfaces/ipatient-verification";
import { EaseLoggerService } from "../../common/services/logger/ease-logger.service";
import { SnackbarService } from "../../common/services/snackbar.service";
import { ParametersService } from 'src/app/common/services/parameters.service';

// Interface for the welcome text
interface IWelcomeText {
	title: string;
	subtitle: string;
	disclaimer: string;
	download: string;
	button: string;
}

/**
 * Represents the SplashScreenComponent class.
 * This component is responsible for displaying the splash screen and handling user interactions.
 */
@Component({
	selector: "app-splash-screen",
	templateUrl: "./splash-screen.component.html",
	styleUrls: ["./splash-screen.component.css"],
})
export class SplashScreenComponent implements OnInit {
	checked: boolean = false;
	showLogo: boolean = true;
	deviceInfo: DeviceInfo;
	isMobile: boolean;
	isTablet: boolean;
	isDesktop: boolean;
	patientDetails?: IPatientDetails;
	accessCode: string = "";

	welcomeText: IWelcomeText = {
		title: "Welcome to Ease,",
		subtitle: "You have been invited by {{patientName}} to receive updates about their care journey. To begin, click \"CONTINUE\" or download the mobile app below.",
		disclaimer: "I agree to the <a href=\"https://www.stryker.com/us/en/legal/privacy.html\">Privacy Policy</a> and <a href=\"https://www.stryker.com/us/en/legal/terms-of-use.html\">Terms of Service</a>",
		download: "Or download the mobile app",
		button: "continue",
	}

	/**
	 * Constructs a new instance of the SplashScreenComponent class.
	 * @param router - The router service for navigating between routes.
	 * @param deviceService - The device detector service for detecting the device type.
	 * @param logger - The logger service for logging messages.
	 * @param snackBar - The snackbar service for displaying snackbars.
	 * @param ewr - The EwrService for retrieving patient details.
	 * @param clipboard - The clipboard service for copying text to the clipboard.
	 */
	constructor(
		private readonly router: Router,
		private readonly deviceService: DeviceDetectorService,
		private readonly logger: EaseLoggerService,
		private readonly snackBar: SnackbarService,
		private readonly ewr: EwrService,
		private readonly parameters: ParametersService,
		private clipboard: Clipboard
	) {
		this.deviceInfo = this.deviceService.getDeviceInfo();
		this.isMobile = this.deviceService.isMobile();
		this.isTablet = this.deviceService.isTablet();
		this.isDesktop = this.deviceService.isDesktop();
	}

	/**
	 * Initializes the component.
	 * This method is called after the component is created.
	 */
	ngOnInit(): void {
		this.logger.log(this.deviceService.getDeviceInfo());
		this.snackBar.openCookies("cookies-snackbar");
		this.loadPatientDetails();
	}

	private loadPatientDetails(): void {
		this.ewr.getPatientDetails().subscribe((data: IPatientDetails | null) => {
			if (data === null) {
				this.logger.log("No patient details found.");
				return;
			}
			
			const patientNameCombo: string = data.patientInfo.firstName + " " + data.patientInfo.lastName + ".";
			this.welcomeText.subtitle = this.welcomeText.subtitle.replace('{{patientName}}', patientNameCombo);
			this.accessCode = data.accessCode;
		});
	}

	/**
	 * Navigates to the verification page.
	 */
	navigateToVerification(): void {
		let redirectUrl: string = `/verification/${this.parameters.getFacilityName()}/${this.parameters.getFacilityId()}/${this.parameters.getReceiverId()}`;
		this.router.navigate([redirectUrl]);
	}

	/**
	 * Handles the logo status change.
	 * @param status - The new status of the logo.
	 */
	handleLogoStatus(status: boolean): void {
		this.showLogo = status;
	}

	/**
	 * Copies the access code to the clipboard.
	 */
	copyAccessCode() {
		this.clipboard.copy(this.accessCode);
	}

	/**
	 * Handles the checkbox change event.
	 * @param event - The checkbox change event.
	 */
	handleCheckboxChange(event: any): void {
		this.checked = event.checked;
	}
}
