import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from 'src/app/environments/environment';
import { EaseLoggerService } from "./logger/ease-logger.service";
import { ParametersService } from "./parameters.service";

/**
 * Service responsible for handling authentication related operations.
 */
@Injectable({
	providedIn: "root",
})
export class AuthService {
	private authKeyPrefix = "authData_";
	private expirationKeyPrefix = "expirationTime_";
	private expirationLengthMS = 24 * 60 * 60 * 1000; // 24 hours

	/**
	 * Constructs an instance of the AuthService.
	 * @param router - The router service.
	 * @param parameters - The parameters service.
	 */
	constructor(
		private readonly router: Router,
		private readonly parameters: ParametersService,
		private readonly logger: EaseLoggerService
	) {
		// localStorage.clear(); // This is here to clear the local storage for testing purposes
		if (!environment.envVariables.production) {
			(window as any).authService = this; // TODO: This is for debug only. Remove for production
		}
	}

	/**
	 * Stores the user credentials in the local storage for a specific receiverID.
	 * @param receiverID - The receiver ID.
	 * @param id - The user ID.
	 * @param secretKey - The user secret key.
	 */
	storeCredentials(receiverID: number, id: string, secretKey: string): void {
		const authData = btoa(`${id}:${secretKey}`);
		localStorage.setItem(this.getAuthKey(receiverID), authData);
		this.resetExpirationTime(receiverID);
	}

	/**
	 * Retrieves the user credentials from the local storage for a specific receiverID.
	 * @param receiverID - The receiver ID.
	 * @returns The user credentials or null if expired.
	 */
	getCredentials(receiverID: number): string | null {
		if (this.isExpired(receiverID)) {
			return null;
		}

		this.resetExpirationTime(receiverID);
		return localStorage.getItem(this.getAuthKey(receiverID));
	}

	/**
	 * Checks if the user is authenticated for a specific receiverID.
	 * @param receiverID - The receiver ID.
	 * @returns True if the user is authenticated, false otherwise.
	 */
	isAuthenticated(receiverID: number): boolean {
		return !!this.getCredentials(receiverID);
	}

	/**
	 * Logs out the user by removing the credentials from the local storage for a specific receiverID and navigating to the verification page.
	 * @param receiverID - The receiver ID.
	 */
	logout(receiverID: number): void {
		let redirectUrl: string = `/invite/${this.parameters.getFacilityName()}/${this.parameters.getFacilityId()}/${receiverID}`;
		localStorage.removeItem(this.getAuthKey(receiverID));
		localStorage.removeItem(this.getExpirationKey(receiverID))
		this.parameters.clearParameters();
		this.router.navigate([redirectUrl]);
	}

	/**
	 * Resets the expiration time for the user credentials for a specific receiverID.
	 * @param receiverID - The receiver ID.
	 */
	resetExpirationTime(receiverID: number) {
		this.logger.log("Expiration reset");
		let expirationTime: number = Date.now() + this.expirationLengthMS;
		localStorage.setItem(this.getExpirationKey(receiverID), expirationTime.toString());
	}

	/**
	 * Retrieves the expiration time for the user credentials for a specific receiverID.
	 * @param receiverID - The receiver ID.
	 * @returns The expiration time in milliseconds or null if not set.
	 */
	getExpirationTime(receiverID: number): number | null {
		let expirationTime: string | null = localStorage.getItem(this.getExpirationKey(receiverID));

		if (expirationTime !== null) {
			return parseInt(expirationTime);
		} else {
			return null;
		}
	}

	/**
	 * Checks if the user credentials have expired for a specific receiverID.
	 * @param receiverID - The receiver ID.
	 * @returns True if the credentials have expired, false otherwise.
	 */
	isExpired(receiverID: number): boolean {
		const expirationTime: number | null = this.getExpirationTime(receiverID);

		if (expirationTime === null) {
			return false;
		}

		return expirationTime <= Date.now();
	}

	/**
 * Generates the storage key for authentication data for a specific receiverID.
 * @param receiverID - The receiver ID.
 * @returns The storage key for authentication data.
 */
	private getAuthKey(receiverID: number): string {
		return `${this.authKeyPrefix}${receiverID}`;
	}

	/**
	 * Generates the storage key for expiration time for a specific receiverID.
	 * @param receiverID - The receiver ID.
	 * @returns The storage key for expiration time.
	 */
	private getExpirationKey(receiverID: number): string {
		return `${this.expirationKeyPrefix}${receiverID}`;
	}
}
