import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { EaseLoggerService } from "../../services/logger/ease-logger.service";
import { IBrandingModel } from "../../services/interfaces/ibranding-model";
import { EwrService } from "../../services/ewr.service";
import { ParametersService } from "../../services/parameters.service";

/**
 * Represents the branding component of the application.
 */
@Component({
	selector: "app-branding",
	template: `
		<div class="full-center branding-container">
			<img
				src="{{ hospitalHeaderLogo }}"
				alt="{{ hospitalName }}"
				id="hospital-logo"
				[ngStyle]="{ width: logoWidth, height: logoHeight }" />
		</div>
	`,
	styles: [
		`
			.branding-container {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				height: 100%;
				overflow: hidden;
			}

			#hospital-logo {
				max-height: 40dvh;
				max-width: 100%;
				object-fit: contain;
			}
		`,
	],
})
export class BrandingComponent implements OnInit {
	@Input() logoWidth: string | undefined;
	@Input() logoHeight: string | undefined;
	@Output() logoStatus = new EventEmitter<boolean>();

	protected hospitalHeaderLogo = "";
	protected hospitalName = "";

	constructor(
		private readonly ewr: EwrService,
		private readonly logger: EaseLoggerService,
		private readonly parameters: ParametersService,
	) { }

	/**
	 * Initializes the branding component.
	 */
	ngOnInit(): void {
		this.ewr.getHospitalBranding().subscribe({
			next: (data: IBrandingModel | null) => {
				if (data === null) {
					this.logoStatus.emit(false);
					this.logger.log(`No hospital logo found`);
				} else {
					this.hospitalHeaderLogo = data.s3BrandingLocation;
					this.parameters.setFacilityName(data.facilityName);
					this.parameters.setFacilityId(data.facilityId);
					this.logger.log(`${this.hospitalHeaderLogo}`);
					this.logoStatus.emit(true);
				}
			},
			error: (error: any) => {
				this.logoStatus.emit(false);
				this.logger.log(`Error fetching hospital branding URL: ${error}`);
			}
		});

		this.logger.log(`logo dims: height: ${this.logoHeight} width: ${this.logoWidth}`);

		this.checkRequiredFields(this.logoHeight);
		this.checkRequiredFields(this.logoWidth);
	}

	/**
	 * Checks if the required fields are present.
	 * @param input - The input to check.
	 * @throws Error if the input is null or undefined.
	 */
	private checkRequiredFields(input: any) {
		if (input === null || input === undefined) {
			throw new Error(
				`app-branding: Attributes [logoHeight, logoWidth] are, both, required`
			);
		}
	}
}
