import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";

@Component({
	selector: 'app-snackbar-error',
	template: `
		<div class="container-fluid snack-wrapper">
			<div class="row error-title-row">
					<mat-icon class="material-symbols-rounded" fontIcon="emergency_home" ></mat-icon>
					<div class="error-title">{{ "toast-messages.backend-communication-error-title" | translate }}</div>
			</div>
			<div class="row error-text-row">
				<p class="error-text" [innerHTML]="'toast-messages.backend-communication-error' | translate"></p>
			</div>
			<div class="row btn-row">
				<button class="btn close-btn" type="button" (click)="dismiss()">
						{{ "common.close-button" | translate | uppercase }}
				</button>
				<button class="btn refresh-btn" type="button" (click)="refreshPage()">
						{{ "common.refresh-button" | translate | uppercase }}
				</button>
			</div>
		</div>
	`,
	styles: [
		`
		::ng-deep .mat-mdc-snackbar-surface {
			padding: 0 !important;
			margin-inline: 1rem !important;
			margin-top: 2rem !important;
			--mdc-snackbar-supporting-text-font: "Noto Sans", sans-serif;
			box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25) !important;
		}

		::ng-deep .mdc-snackbar__label {
			padding: 0 !important;
		}

		.snack-wrapper {
			padding: 0;
		}

		.row {
			display: flex;
			justify-content: left;
			align-items: center;
			width: 100%;
			margin: 0;
		}

		.row.error-title-row {
			height: 2.5625rem;
			background: var(--color-error-snackbar);
			border-radius: 0.5rem 0.5rem 0 0;
			padding: 0 0 0 1rem;
		}

		.material-symbols-rounded {
			padding: 0;
		}

		.error-title {
			font-size: 1rem;
			font-weight: 500;
			margin-left: 1rem;
			width: auto;
			margin-left: 0.5rem;
			padding: 0;
		}

		.error-text {
			color: var(--color-text);
			font-size: 0.75rem;
			padding: 0;
			line-height: 1rem;
			margin: 0;
		}

		.error-text-row{
			padding: 0.625rem 1.2rem 0.625rem 1.2rem;
		}

		.btn-row {
			display: flex;
			justify-content: right;
			align-items: center;
			padding: 0 1rem 1.5rem 1rem;
		}

		.btn {
			color: var(--color-white);
			background: var(--color-mid-gray);
			font-size: 0.875rem;
			padding-block: 0.625rem;
			padding-inline: 1.25rem;
			border-radius: 1.4rem;
			width: auto;
		}

		.close-btn {
			color: var(--color-mid-gray);
			background: white;
			margin-right: 0.5rem;
		}

		.close-btn:hover {
			background: var(--color-light-gray);
			color: white;
		}

		.refresh-btn:hover {
			background: var(--color-dark-gray);
		}
		`,
	],
})

export class SnackbarErrorComponent {
	constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }
	dismiss() {
		this.data.preClose();
	}
	
	refreshPage() {
		location.reload();
	}
}
